import * as Sentry from "@sentry/gatsby";
import { navigate } from "gatsby";
import moment from "moment";
import { useActions, useReduxState } from "re-reduced";
import React, { useEffect } from "react";

import ErrorBoundary from "@components/errorBoundary";
import Scanner from "@components/scanner";
import { Loading } from "@components/shared/sub";
import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { pages } from "@util/constants";
import { Container } from "@util/standard";


const IndexPage = () => {
  const { fetchingEvent, sessionExpiry, loggedIn, auth, user } = useReduxState({
    fetchingEvent: selectors.getFetchingEvent,
    sessionExpiry: selectors.getSessionExpiry,
    auth: selectors.getAuth,
    loggedIn: selectors.getLoggedIn,
    user: selectors.getUser,
  });
  const { logout, updateRoute } = useActions(actions);

  useEffect(() => {
    if (moment().isAfter(moment(sessionExpiry)) && loggedIn) {
      logout();
    }
  }, []);

  
  useEffect(() => {
    if (loggedIn && auth && user) {
      updateRoute("scanner");
      Sentry.setTag("organization", auth.orgId);
      Sentry.setTag("organization-name", auth.orgName);
      Sentry.configureScope((scope) => scope.setUser(user));
    } else {
      updateRoute("login");
      navigate(pages.login);
    }
  }, [loggedIn, auth, user]);

  if (!loggedIn) {
    return null;
  }

  return (
    <ErrorBoundary>
      {fetchingEvent ? (
        <Container width="100%" justifyContent="center" alignItems="center" margin="16px 0 0 0">
          <Loading />
        </Container>
      ) : (
        <>
          <title>Home Page</title>
          <Scanner />
        </>
      )}
    </ErrorBoundary>
  );
};

export default IndexPage;
