import React, { Component, ReactNode } from "react";
import * as Sentry from "@sentry/gatsby";

interface Props {
  children: ReactNode;
}

export default class ErrorBoundary extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (process.env.GATSBY_NODE_ENV === "production") {
      Sentry.configureScope((scope: any) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
      });
      Sentry.captureException(error);
    } else {
      console.log({ error, errorInfo });
    }
  }

  render() {
    return <div style={{ height: "100%" }}>{this.props.children}</div>;
  }
}
